import { Parallax } from "react-parallax";
import { Link } from "react-scroll";
import Navbar from '../layout/Navbar';
import Hero from '../section/hero';
import About from '../section/about';
import Blockquote from '../section/blockquote';
import BlockquoteVersatile from '../section/blockquoteVersatile';
import Resume from '../section/resume';
import References from '../section/references';
import Footer from '../section/footer';
import Portfolio from '../section/gallery';
import Preloader from "../layout/Preloader";
import ScrollToTopBtn from '../layout/ScrollToTop';
import { createGlobalStyle } from 'styled-components';

const image1 = "./img/background/1.jpg";

const GlobalStyles = createGlobalStyle`
  .navbar-brand .imginit{
	  display: block ;
	}
	.navbar-brand .imgsaly{
	  display: none !important;
	}
`;

function home() {
	return (
		<>
			<GlobalStyles />
			<Preloader />
			<div className="home">

				<header id="header-wrap">
					<Navbar />
				</header>

				{/* HERO */} 
				<section id="hero-area" className="bg-bottom py-0">
					<Parallax bgImage={image1} strength={300}>
						<Hero />
						<Link smooth spy to="about">
							<span className="mouse transition" id="fly">
								<span className="scroll"></span>
							</span>
						</Link>
					</Parallax>
				</section>

				{/* ABOUT */}
				<section id="overview" className="pb-0">
					<Blockquote />
					<About />
				</section>

				{/* PORTFOLIO */}
				<section id="portfolio">
					<Portfolio />
				</section>

				{/* RESUME */}
				<section id="resume" className="pb-0">
					<BlockquoteVersatile />
					<Resume />
				</section>

				{/* REFERENCES */}
				<section id="references" className="pb-0">
					<References />
					<Footer />
				</section>

				{/* Blog
				<section id="blog" className="pb-0">
					<Blog />
				</section>
				*/}

				{/* contact 
				  <section id="contact" className="pb-0">
					<Contact/>
					<Footer/>
				</section>
				*/}

				<div className="float-text">
					<div className="de_social-icons">
						<span className="buton"><a href="https://www.facebook.com/gamearchitect" target="blank" className="link-info" rel="noreferrer"><i className="fa fa-facebook fa-lg"></i></a></span>
						<span className="buton"><a href="https://twitter.com/mattfinitz" target="_blank" className="link-info" rel="noreferrer"><i className="fa fa-twitter fa-lg"></i></a></span>
						<span className="buton"><a href="https://www.linkedin.com/in/matthew-finitz-b146301/" target="_blank" className="link-info" rel="noreferrer"><i className="fa fa-linkedin fa-lg"></i></a></span>
						<span className="buton"><a href="https://www.instagram.com/m.r.finitz/" target="_blank" className="link-info" rel="noreferrer"><i className="fa fa-instagram fa-lg"></i></a></span>
					</div>
					<span>Follow Me</span>
				</div>
			</div>
			<ScrollToTopBtn />
		</>
	);
}

export default home;

