import React from 'react';
import AOS from 'aos';
AOS.init();



const references = () => {
	return(
		<div className="container">
			<div className="row">
				<div className="row">
					<div className="col-md-12 text-center">
						<h2 data-aos="fade-up"
							data-aos-delay="0"
							data-aos-duration="1000"
							data-aos-easing="ease"
							data-aos-once="true">References</h2>
					</div>
					<div className="col-md-12 m-auto"
						data-aos="fade-up"
						data-aos-delay="60"
						data-aos-duration="1000"
						data-aos-easing="ease"
						data-aos-once="true">
						<ul className="d_timeline">
							<li className="d_timeline-item">
								<h3 className="d_timeline-title">Experis</h3>
								<p className="d_timeline-text">
									<a href="https://www.linkedin.com/in/ian-nelson-b12618b4/" target="_blank" className="link-info" rel="noreferrer"><i className="fa fa-envelope fa-lg"></i></a><span className="color"> Ian Nelson</span> (QA Manager, Microsoft Studios) - Reported to me. Lead a team of <span className="color">(40+)</span> games QA testers.<br/>
									<a href="https://www.linkedin.com/in/marcus-king-0393691/" target="_blank" className="link-info" rel="noreferrer"><i className="fa fa-envelope fa-lg"></i></a><span className="color"> Marcus King</span> (QA Manager, Microsoft Studios) - Reported to me. Lead a team of <span className="color">(75+)</span> games QA testers.
								</p>
							</li>
							<li className="d_timeline-item">
								<h3 className="d_timeline-title">Dreamworld</h3>
								<p className="d_timeline-text">
									<a href="mailto:brian.rivers@live.com" target="_blank" className="link-info" rel="noreferrer"><i className="fa fa-envelope fa-lg"></i></a><span className="color"> Brian Rivers</span> (Prinicipal Game Developer) - Worked alongside developing Dreamworld.
								</p>
							</li>
							<li className="d_timeline-item">
								<h3 className="d_timeline-title">Amazon Games</h3>
								<p className="d_timeline-text">
									<a href="mailto:scosmt@amazon.com" target="_blank" className="link-info" rel="noreferrer"><i className="fa fa-envelope fa-lg"></i></a><span className="color"> Scott Smith</span> (Sr.SDET, Amazon Games Test Platform) - Reported to me. Helped develop our Game Lab service for Amazon Studios.<br />
									<a href="mailto:linkedin@goldenwolf.systems" target="_blank" className="link-info" rel="noreferrer"><i className="fa fa-envelope fa-lg"></i></a><span className="color"> Jon Wills</span> (Sr.SDET, Amazon Games Test Platform) - Reported to me. Helped develop our Game Deploy and other services for Amazon Studios.<br />
									<a href="mailto:wadejfong@gmail.com" target="_blank" className="link-info" rel="noreferrer"><i className="fa fa-envelope fa-lg"></i></a><span className="color"> Wade Fong</span> (Lead SDET, New World) - Reported to me. Oversaw New World test automation.
								</p>
							</li>
							<li className="d_timeline-item">
								<h3 className="d_timeline-title">Amazon Alexa</h3>
								<p className="d_timeline-text">
									<a href="mailto:gbelin@amazon.com" target="_blank" className="link-info" rel="noreferrer"><i className="fa fa-envelope fa-lg"></i></a><span className="color"> Gilles Belin</span> (Director, Alexa Service Platform) - Reported to him while I created our Alexa Test Platform group and built the foundational tools.<br />
									<a href="https://www.linkedin.com/in/anirudhdaga/" target="_blank" className="link-info" rel="noreferrer"><i className="fa fa-envelope fa-lg"></i></a><span className="color"> Anirudh Daga</span> (Sr.Manager, Alexa Test Platform) - Reported to him after I created the Alexa Test Platform group and built the foundational tools.<br />
									<a href="https://www.linkedin.com/in/anshuman-chadha-8ab58717/" target="_blank" className="link-info" rel="noreferrer"><i className="fa fa-envelope fa-lg"></i></a><span className="color"> Anshuman Chada</span> (Sr.Manager, Alexa Core Services) - Reported to him as an SDET on the Alexa Services team during beta.
								</p>
							</li>
							<li className="d_timeline-item">
								<h3 className="d_timeline-title">Barnes &amp; Noble / Nook</h3>
								<p className="d_timeline-text">
									<a href="mailto:jeff.d.kirby@gmail.com" target="_blank" className="link-info" rel="noreferrer"><i className="fa fa-envelope fa-lg"></i></a><span className="color"> Jeff Kirby</span> (Staff Engineer) - Worked alongside me as a dev-team counterpart.<br />
									<a href="mailto:richa118@gmail.com" target="_blank" className="link-info" rel="noreferrer"><i className="fa fa-envelope fa-lg"></i></a><span className="color"> Richa Arora</span> (QA Engineer) - Reported to me as part of the Nook QA team.
								</p>
							</li>
							<li className="d_timeline-item">
								<h3 className="d_timeline-title">EA Games</h3>
								<p className="d_timeline-text">
									<a href="mailto:mr-linkedin@webdeck.com" target="_blank" className="link-info" rel="noreferrer"><i className="fa fa-envelope fa-lg"></i></a><span className="color"> Mike Riccio</span> (Director, EA Digital Platform) - Reported to him while I managed the test automation team.<br />
									<a href="mailto:ritchie@mac.com" target="_blank" className="link-info" rel="noreferrer"><i className="fa fa-envelope fa-lg"></i></a><span className="color"> Ian Ritchie</span> (Sr.SDE Manager, EA Digital Platform) - Worked as my dev-manager peer.<br />
									<a href="https://www.linkedin.com/in/mark-maratea-73b9653/" target="_blank" className="link-info" rel="noreferrer"><i className="fa fa-envelope fa-lg"></i></a><span className="color"> Mark Maratea</span> (Sr.SDE, EA Digital Platform) - Worked alongside me as a dev-team counterpart.
								</p>
							</li>
							<li className="d_timeline-item">
								<h3 className="d_timeline-title">Zynga</h3>
								<p className="d_timeline-text">
									<a href="mailto:marcus@forevrgames.com" target="_blank" className="link-info" rel="noreferrer"><i className="fa fa-envelope fa-lg"></i></a><span className="color"> Marcus Segal</span> (SVP Operations, Zynga) - Dotted-line to Marcus on automation initiatives for the org.
								</p>
							</li>
							<li className="d_timeline-item">
								<h3 className="d_timeline-title">Netflix</h3>
								<p className="d_timeline-text">
									<a href="mailto:gautamrk@gmail.com" target="_blank" className="link-info" rel="noreferrer"><i className="fa fa-envelope fa-lg"></i></a><span className="color"> Gautam Kotwal</span> (Sr.Manager, Netflix Certification Platform) - Reported to him while developing Netflix's external certification platform tools.
								</p>
							</li>
							<li className="d_timeline-item">
								<h3 className="d_timeline-title">Adobe</h3>
								<p className="d_timeline-text">
									<a href="mailto:lauren.park@yahoo.com" target="_blank" className="link-info" rel="noreferrer"><i className="fa fa-envelope fa-lg"></i></a><span className="color"> Lauren Park</span> (QA Manager, Adobe Flex) - Reported to her while automating Flex testing.
								</p>
							</li>
							<li className="d_timeline-item">
								<h3 className="d_timeline-title">Microsoft</h3>
								<p className="d_timeline-text">
									<a href="mailto:eric.wennerlund@outlook.com" target="_blank" className="link-info" rel="noreferrer"><i className="fa fa-envelope fa-lg"></i></a><span className="color"> Eric Wennerlund</span> (QA Manager) - Reported to him while automating Microsoft's IPTV (AT&T U-Verse).
								</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default references;