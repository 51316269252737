import React from 'react';
import Masonry from "react-masonry-component";
import { createGlobalStyle } from 'styled-components';
import AOS from 'aos';
AOS.init();

const GlobalStyles = createGlobalStyle`
	body.hide{
		  overflow-y: hidden; /* Hide vertical scrollbar */
		  overflow-x: hidden; /* Hide horizontal scrollbar */
		}
`;

const Gallery = function () {
	/* lightbox1 */
	const [lighbx, setlighbx] = React.useState(false);




	const handleBtnClick = (): void => {
		setlighbx(!lighbx);
		var x = document.getElementsByTagName("BODY")[0];
		x.style.overflow = "hidden";
	};
	const handleBtnClickclose = (): void => {
		setlighbx(!lighbx);
		var x = document.getElementsByTagName("BODY")[0];
		x.style.overflow = "auto";
	};

	/* lightbox2 */
	const [lighbx1, setlighbx1] = React.useState(false);
	const handleBtnClick1 = (): void => {
		setlighbx1(!lighbx1);
		var x = document.getElementsByTagName("BODY")[0];
		x.style.overflow = "hidden";
	};
	const handleBtnClickclose1 = (): void => {
		setlighbx1(!lighbx1);
		var x = document.getElementsByTagName("BODY")[0];
		x.style.overflow = "auto";
	};

	/* lightbox2 */
	const [lighbx2, setlighbx2] = React.useState(false);
	const handleBtnClick2 = (): void => {
		setlighbx2(!lighbx2);
		var x = document.getElementsByTagName("BODY")[0];
		x.style.overflow = "hidden";
	};
	const handleBtnClickclose2 = (): void => {
		setlighbx2(!lighbx2);
		var x = document.getElementsByTagName("BODY")[0];
		x.style.overflow = "auto";
	};




	const [warpinBox, setWarpinBox] = React.useState(false);
	const handleWarpinClick = (): void => {
		setWarpinBox(!warpinBox);
		var x = document.getElementsByTagName("BODY")[0];
		x.style.overflow = "hidden";
	};
	const handleWarpinClose = (): void => {
		setWarpinBox(!warpinBox);
		var x = document.getElementsByTagName("BODY")[0];
		x.style.overflow = "auto";
	};

	const [qwickBox, setQwickBox] = React.useState(false);
	const handleQwickClick = (): void => {
		setQwickBox(!qwickBox);
		var x = document.getElementsByTagName("BODY")[0];
		x.style.overflow = "hidden";
	};
	const handleQwickClose = (): void => {
		setQwickBox(!qwickBox);
		var x = document.getElementsByTagName("BODY")[0];
		x.style.overflow = "auto";
	};

	const [academiaBox, setAcademiaBox] = React.useState(false);
	const handleAcademiaClick = (): void => {
		setAcademiaBox(!academiaBox);
		var x = document.getElementsByTagName("BODY")[0];
		x.style.overflow = "hidden";
	};
	const handleAcademiaClose = (): void => {
		setAcademiaBox(!academiaBox);
		var x = document.getElementsByTagName("BODY")[0];
		x.style.overflow = "auto";
	};

	const [aggressantBox, setAggressantBox] = React.useState(false);
	const handleAggressantClick = (): void => {
		setAggressantBox(!aggressantBox);
		var x = document.getElementsByTagName("BODY")[0];
		x.style.overflow = "hidden";
	};
	const handleAggressantClose = (): void => {
		setAggressantBox(!aggressantBox);
		var x = document.getElementsByTagName("BODY")[0];
		x.style.overflow = "auto";
	};

	const [blizzardBox, setBlizzardBox] = React.useState(false);
	const handleBlizzardClick = (): void => {
		setBlizzardBox(!blizzardBox);
		var x = document.getElementsByTagName("BODY")[0];
		x.style.overflow = "hidden";
	};
	const handleBlizzardClose = (): void => {
		setBlizzardBox(!blizzardBox);
		var x = document.getElementsByTagName("BODY")[0];
		x.style.overflow = "auto";
	};

	const [gamBox, setGamBox] = React.useState(false);
	const handleGamClick = (): void => {
		setGamBox(!gamBox);
		var x = document.getElementsByTagName("BODY")[0];
		x.style.overflow = "hidden";
	};
	const handleGamClose = (): void => {
		setGamBox(!gamBox);
		var x = document.getElementsByTagName("BODY")[0];
		x.style.overflow = "auto";
	};

	const [wogBox, setWogBox] = React.useState(false);
	const handleWogClick = (): void => {
		setWogBox(!wogBox);
		var x = document.getElementsByTagName("BODY")[0];
		x.style.overflow = "hidden";
	};
	const handleWogClose = (): void => {
		setWogBox(!wogBox);
		var x = document.getElementsByTagName("BODY")[0];
		x.style.overflow = "auto";
	};

	const [autoGearsBox, setAutoGearsBox] = React.useState(false);
	const handleAutoGearsClick = (): void => {
		setAutoGearsBox(!autoGearsBox);
		var x = document.getElementsByTagName("BODY")[0];
		x.style.overflow = "hidden";
	};
	const handleAutoGearsClose = (): void => {
		setAutoGearsBox(!autoGearsBox);
		var x = document.getElementsByTagName("BODY")[0];
		x.style.overflow = "auto";
	};

	const [autoCostsBox, setAutoCostsBox] = React.useState(false);
	const handleAutoCostsClick = (): void => {
		setAutoCostsBox(!autoCostsBox);
		var x = document.getElementsByTagName("BODY")[0];
		x.style.overflow = "hidden";
	};
	const handleAutoCostsClose = (): void => {
		setAutoCostsBox(!autoCostsBox);
		var x = document.getElementsByTagName("BODY")[0];
		x.style.overflow = "auto";
	};


	return (
		<div className="container">
			<GlobalStyles />
			<div className="row">
				<div className="col-md-12 text-center">
					<h2>Portfolio</h2>
					<div className="space-border"></div>
				</div>
			</div>
			<Masonry className={"row my-gallery-class"} elementType={"div"}>
				<div className="col-lg-4 image-element-class de_modal de_modal"
					data-aos="fade-up"
					data-aos-once="true"
					onClick={handleWarpinClick}>
					<div className="card-image-1">
						<div className="d-text">
							<h3>Warpin: Creation (VR)</h3>
							<h5 className="d-tag">Game Development</h5>
						</div>
						<img src="./img/gallery/CreationSplash.png" alt="gallery" />
					</div>
				</div>
				<div className="col-lg-4 image-element-class de_modal"
					data-aos="fade-up"
					data-aos-once="true"
					onClick={handleQwickClick}>
					<div className="card-image-1">
						<div className="d-text">
							<h3>Qwick QA Consult</h3>
							<h5 className="d-tag">Consulting</h5>
						</div>
						<img src="./img/gallery/QaDirector.jpg" alt="gallery" />
					</div>
				</div>
				<div className="col-lg-4 image-element-class de_modal"
					data-aos="fade-up"
					data-aos-once="true"
					onClick={handleAcademiaClick}>
					<div className="card-image-1">
						<div className="d-text">
							<h3>World of Academia</h3>
							<h5 className="d-tag">Product Design</h5>
						</div>
						<img src="./img/gallery/Academia.jpg" alt="gallery" />
					</div>
				</div>
				<div className="col-lg-4 image-element-class de_modal"
					data-aos="fade-up"
					data-aos-once="true"
					onClick={handleWogClick}>
					<div className="card-image-1">
						<div className="d-text">
							<h3>Workout Gears</h3>
							<h5 className="d-tag">Mobile Development</h5>
						</div>
						<img src="./img/gallery/WorkoutGears.jpg" alt="gallery" />
					</div>
				</div>
				<div className="col-lg-4 image-element-class de_modal"
					data-aos="fade-up"
					data-aos-once="true"
					onClick={handleAutoGearsClick}>
					<div className="card-image-1">
						<div className="d-text">
							<h3>Mechanics of Automation</h3>
							<h5 className="d-tag">Test Automation</h5>
						</div>
						<img src="./img/gallery/Presentations.jpg" alt="gallery" />
					</div>
				</div>
				<div className="col-lg-4 image-element-class de_modal"
					data-aos="fade-up"
					data-aos-once="true"
					onClick={handleAutoCostsClick}>
					<div className="card-image-1">
						<div className="d-text">
							<h3>Costs of Automation</h3>
							<h5 className="d-tag">Test Automation</h5>
						</div>
						<img src="./img/gallery/AutoCosts.jpg" alt="gallery" /> 
					</div>
				</div>
				<div className="col-lg-4 image-element-class de_modal"
					data-aos="fade-up"
					data-aos-once="true"
					onClick={handleGamClick}>
					<div className="card-image-1">
						<div className="d-text">
							<h3>Gods And Monsters</h3>
							<h5 className="d-tag">TableTop Game Development</h5>
						</div>
						<img src="./img/gallery/GodsAndMonsters.jpg" alt="gallery" />
					</div>
				</div>
				<div className="col-lg-4 image-element-class de_modal"
					data-aos="fade-up"
					data-aos-once="true"
					onClick={handleBlizzardClick}>
					<div className="card-image-1">
						<div className="d-text">
							<h3>Blizzard QA Consult</h3>
							<h5 className="d-tag">Consulting</h5>
						</div>
						<img src="./img/gallery/BlizzardConsulting.jpg" alt="gallery" />
					</div>
				</div>
				<div className="col-lg-4 image-element-class de_modal"
					data-aos="fade-up"
					data-aos-once="true"
					onClick={handleAggressantClick}>
					<div className="card-image-1">
						<div className="d-text">
							<h3>Aggressant Studios</h3>
							<h5 className="d-tag">Web Development</h5>
						</div>
						<img src="./img/gallery/Aggressant.jpg" alt="gallery" />
					</div>
				</div>
			</Masonry>

			{/* lightbox1 */}
			{lighbx && (
				<div className="LightboxGal">
					<div className="closeGal">
						<button className="button-close" onClick={handleBtnClickclose}></button>
					</div>
					<div className="v-center w-100">
						<div className="mainLightbox container">
							<div className="row g-5">
								<div className="col-lg-8">
									<img src="./img/gallery-big/1.jpg" alt="" className="img-fluid" />
								</div>

								<div className="col-lg-4 de_project-info">
									<h3>Interior Design Website</h3>
									<p>Duis ad adipisicing id id in duis nisi qui veniam esse voluptate cillum pariatur cupidatat ut dolore amet aliquip cillum ad minim cillum nulla consectetur dolor culpa deserunt mollit dolor ea pariatur laboris sed tempor laboris quis commodo. Ut duis cupidatat consectetur cillum veniam eu quis minim irure fugiat ut consequat do veniam duis dolor quis cupidatat deserunt ut qui minim id dolor nisi sed non ut proident enim culpa dolor elit dolor aliquip dolore enim excepteur.</p>

									<div className="de_project-details">
										<div className="d-field">
											<i className="fa fa-user-o"></i>Client: <span>Envato</span>
										</div>
										<div className="d-field">
											<i className="fa fa-file-text-o"></i>Type: <span>Branding</span>
										</div>
										<div className="d-field">
											<i className="fa fa-calendar-o"></i>Year: <span>2020</span>
										</div>
										<div className="d-field">
											<i className="fa fa-external-link"></i>Preview: <span><a href="https://www.envato.com" target="_blank" rel="noreferrer">www.envato.com</a></span>
										</div>
									</div>
									<div className="spacer-30"></div>
									<blockquote>
										"Very well done theme. Versatile, extremely well coded, and gorgeous. That's a great combo. Highly recommended."
										<span>John Archi</span>
									</blockquote>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			{/* lightbox2 */}
			{lighbx1 && (
				<div className="LightboxGal">
					<div className="closeGal">
						<button className="button-close" onClick={handleBtnClickclose1}></button>
					</div>
					<div className="v-center w-100">
						<div className="mainLightbox container">
							<div className="row g-5">
								<div className="col-lg-8">
									<div className="row g-4">
										<div className="col-lg-12 item">
											<img src="./img/single-gallery/pf-2-1.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/single-gallery/pf-2-2.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/single-gallery/pf-2-3.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-12 item">
											<img src="./img/single-gallery/pf-2-4.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/single-gallery/pf-2-5.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/single-gallery/pf-2-6.jpg" alt="galleryimage" className="img-fluid" />
										</div>
									</div>
								</div>

								<div className="col-lg-4 de_project-info">
									<h3>Interior Design Website</h3>
									<p>Duis ad adipisicing id id in duis nisi qui veniam esse voluptate cillum pariatur cupidatat ut dolore amet aliquip cillum ad minim cillum nulla consectetur dolor culpa deserunt mollit dolor ea pariatur laboris sed tempor laboris quis commodo. Ut duis cupidatat consectetur cillum veniam eu quis minim irure fugiat ut consequat do veniam duis dolor quis cupidatat deserunt ut qui minim id dolor nisi sed non ut proident enim culpa dolor elit dolor aliquip dolore enim excepteur.</p>

									<div className="de_project-details">
										<div className="d-field">
											<i className="fa fa-user-o"></i>Client: <span>Envato</span>
										</div>
										<div className="d-field">
											<i className="fa fa-file-text-o"></i>Type: <span>Branding</span>
										</div>
										<div className="d-field">
											<i className="fa fa-calendar-o"></i>Year: <span>2020</span>
										</div>
										<div className="d-field">
											<i className="fa fa-external-link"></i>Preview: <span><a href="https://www.envato.com" target="_blank" rel="noreferrer">www.envato.com</a></span>
										</div>
									</div>
									<div className="spacer-30"></div>
									<blockquote>
										"Very well done theme. Versatile, extremely well coded, and gorgeous. That's a great combo. Highly recommended."
										<span>John Archi</span>
									</blockquote>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			{/* lightboxvideo */}
			{lighbx2 && (
				<div className="LightboxGal">
					<div className="closeGal">
						<button className="button-close" onClick={handleBtnClickclose2}></button>
					</div>
					<div className="v-center w-100">
						<div className="mainLightbox container">
							<div className="row g-5">
								<div className="col-lg-8">
									<video className="pop" controls autoPlay loop>
										<source src="./video/local-video-2.mp4" type="video/mp4" />
										Your browser does not support the video tag.
									</video>
								</div>

								<div className="col-lg-4 de_project-info">
									<h3>Self Hosted Video</h3>
									<p>Duis ad adipisicing id id in duis nisi qui veniam esse voluptate cillum pariatur cupidatat ut dolore amet aliquip cillum ad minim cillum nulla consectetur dolor culpa deserunt mollit dolor ea pariatur laboris sed tempor laboris quis commodo. Ut duis cupidatat consectetur cillum veniam eu quis minim irure fugiat ut consequat do veniam duis dolor quis cupidatat deserunt ut qui minim id dolor nisi sed non ut proident enim culpa dolor elit dolor aliquip dolore enim excepteur.</p>

									<div className="de_project-details">
										<div className="d-field">
											<i className="fa fa-user-o"></i>Client: <span>Envato</span>
										</div>
										<div className="d-field">
											<i className="fa fa-file-text-o"></i>Type: <span>Branding</span>
										</div>
										<div className="d-field">
											<i className="fa fa-calendar-o"></i>Year: <span>2020</span>
										</div>
										<div className="d-field">
											<i className="fa fa-external-link"></i>Preview: <span><a href="https://www.envato.com" target="_blank" rel="noreferrer">www.envato.com</a></span>
										</div>
									</div>
									<div className="spacer-30"></div>
									<blockquote>
										"Very well done theme. Versatile, extremely well coded, and gorgeous. That's a great combo. Highly recommended."
										<span>John Archi</span>
									</blockquote>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			{/* warpinBox */}
			{warpinBox && (
				<div className="LightboxGal">
					<div className="closeGal">
						<button className="button-close" onClick={handleWarpinClose}></button>
					</div>
					<div className="v-center w-100">
						<div className="mainLightbox container">
							<div className="row g-5">
								<div className="col-lg-8">
									<div className="row g-4">
										<div className="col-lg-12 item">
											<img src="./img/gallery/warpin/Wallpaper-Warpin.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/warpin/Wallpaper-CharacterCreate.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/warpin/Wallpaper-Mutant.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-12 item">
											<img src="./img/gallery/warpin/Wallpaper-Midimension.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/warpin/Wallpaper-Fortress.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/warpin/Wallpaper-WarpGate.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-12 item">
											<img src="./img/gallery/warpin/Wallpaper-MidLanding.jpg" alt="galleryimage" className="img-fluid" />
										</div>
									</div>
								</div>

								<div className="col-lg-4 de_project-info">
									<h3>Warpin: Creation (VR)</h3>

									<div className="de_project-details">
										<div className="d-field">
											<i className="fa fa-user-o"></i>Focus: <span>Game Development</span>
										</div>
										<div className="d-field">
											<i className="fa fa-file-text-o"></i>Category: <span>Hobby</span>
										</div>
										<div className="d-field">
											<i className="fa fa-calendar-o"></i>Year: <span>2016</span>
										</div>
										<div className="d-field">
											<i className="fa fa-external-link"></i>Preview: <span><a href="https://store.steampowered.com/app/548740/Warpin_Creation_VR/" target="_blank" rel="noreferrer">Steam</a></span>
										</div>
									</div>
									<br />
									<br />

									<h4>Restore the Glory of Creation</h4>
									<p>
										At the dawn of reality, two great essences imagined a grand plan for life. But there was a flaw in
										their design. New worlds beyond reach, began to threaten the very dimensions they had been
										constructed from. Now, you must travel to these maze-like fragments of existence and purge them of
										flaws while uncovering a deeper truth about the warpstream itself.
									</p>
									<p>
										Built from the ground up for VR, Warpin: Creation takes you to the midimensions that lie in the
										warpstreams between our world and others. Recruited by an unknown great one, your combat skills
										are all that stand between life's premise and the ultimate unraveling of reality. Journey through
										endless midimensions seeking to restore the glory of creation. Overcome enemy strategies, battle
										monsters, gather artifacts, and upgrade abilities to become a true guardian of existence.
									</p>
									<br/>

									<h4>Features</h4>
									<ul style={{ paddingLeft: 0 }}>
										<li>
											Active VR Combat: Utilize your body to wield swords, shields, axes, rifles, and more.
											Deflect enemy attacks. Quickly switch between sword and board, dual-wielding, or other
											weapon configurations to create a rapid and flexible combat style that suits your needs
											at any moment.
										</li>
										<li>
											Forge Powerful Weapons: Acquire magical and technogical abilities and equip your weapons
											with them. Mix and match different abilities to build unique battle styles and strengths.
										</li>
										<li>
											Prepare For Battle: Stock your safe hold with a display of stunning weaponry and create
											a staging room for your battles to restore creation.
										</li>
										<li>
											Seek and Destroy: Navigate thru maze-like midimensions to find flaws and purge them.
											Use your pathfinding and memory skills to find the next flaw.
										</li>
										<li>
											Dynamic Levels and increasing complexity: Battle against an increasing amount of flaw
											cunning and strategy. Fight your way thru increasingly larger midimensions and enemy
											fortresses.
										</li>
									</ul>

									<div className="spacer-30"></div>
									<blockquote>
										"Fantastic concept. It's a little rough around the edges since it's still in early access,
										but I can picture a fully polished version of this game as being the sort of thing that
										people got into VR for. It's kind of a mix between an action game and a puzzle solver.
										Visuals are beautiful."
										<span>jsas819</span>
									</blockquote>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			{/* qwickBox */}
			{qwickBox && (
				<div className="LightboxGal">
					<div className="closeGal">
						<button className="button-close" onClick={handleQwickClose}></button>
					</div>
					<div className="v-center w-100">
						<div className="mainLightbox container">
							<div className="row g-5">
								<div className="col-lg-8">
									<div className="row g-4">
										<div className="col-lg-12 item">
											<img src="./img/gallery/qwick/Slide1.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/qwick/Slide2.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/qwick/Slide3.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-12 item">
											<img src="./img/gallery/qwick/Slide4.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/qwick/Slide5.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/qwick/Slide6.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-12 item">
											<img src="./img/gallery/qwick/Slide7.jpg" alt="galleryimage" className="img-fluid" />
										</div>
									</div>
								</div>

								<div className="col-lg-4 de_project-info">
									<h3>Qwick QA Consult</h3>

									<div className="de_project-details">
										<div className="d-field">
											<i className="fa fa-user-o"></i>Focus: <span>Test Automation</span>
										</div>
										<div className="d-field">
											<i className="fa fa-file-text-o"></i>Category: <span>Consulting</span>
										</div>
										<div className="d-field">
											<i className="fa fa-calendar-o"></i>Year: <span>2022</span>
										</div>
										<div className="d-field">
											<i className="fa fa-external-link"></i>Preview: <span><a href="./img/gallery/qwick/QwickDirectorConsult.pptx" download="QwickDirectorConsult">Presentation</a></span>
										</div>
									</div>
									<br />
									<br />

									<h4>Help!</h4>
									<p>
										Qwick was looking to establish a formal automation program past the minor scripting effort they 
										had previously divided among their dev team. After having seen success in those efforts, they 
										sought guidance on how best to turn up the efforts. So after a brief chat, I sat down for a few 
										days and crafted a presentation on the ideology they'd seek, how they'd track success, how 
										they'd build their team, and lastly, how they'd build automation over the next 3-24 months.
									</p>
									<br />

									<h4>Prompts Covered</h4>
									<ul style={{ paddingLeft: 0 }}>
										<li>
											How you think about QA? The perspective you bring (QA Ideology)
										</li>
										<li>
											What teams would be crucial to work with? (QA Ideology)
										</li>
										<li>
											How would you track success? (Tracking Success)
										</li>
										<li>
											What roles would you need to hire and what would they do? (Building QA)
										</li>
										<li>
											How would you iteratively build automation. (Building Automation)
										</li>
										<li>
											What tests would be important to implement? (Building Automation)
										</li>
									</ul>

									<div className="spacer-30"></div>
									<blockquote>
										"Wow this looks great! We'd love to talk more about this."
										<span>Wyatt Hoffman</span>
									</blockquote>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			{/* academiaBox */}
			{academiaBox && (
				<div className="LightboxGal">
					<div className="closeGal">
						<button className="button-close" onClick={handleAcademiaClose}></button>
					</div>
					<div className="v-center w-100">
						<div className="mainLightbox container">
							<div className="row g-5">
								<div className="col-lg-8">
									<div className="row g-4">
										<div className="col-lg-12 item">
											<img src="./img/gallery/academia/AcademiaGeography.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/academia/AcademiaCurriculum.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/academia/AcademiaWheel.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-12 item">
											<img src="./img/gallery/academia/AcademiaRadius.jpg" alt="galleryimage" className="img-fluid" />
										</div>
									</div>
								</div>

								<div className="col-lg-4 de_project-info">
									<h3>World of Academia</h3>

									<div className="de_project-details">
										<div className="d-field">
											<i className="fa fa-user-o"></i>Focus: <span>Product Design</span>
										</div>
										<div className="d-field">
											<i className="fa fa-file-text-o"></i>Category: <span>Education</span>
										</div>
										<div className="d-field">
											<i className="fa fa-calendar-o"></i>Year: <span>2009</span>
										</div>
										<div className="d-field">
											<i className="fa fa-external-link"></i>Preview: <span><a href="./img/gallery/academia/WorldOfAcademia.pdf" download="WorldOfAcademia">Submission</a></span>
										</div>
									</div>
									<br />
									<br />

									<h3>Congratulations! You've Won the Curiosita Scholarship!</h3>
									<p>
										In the spring of 2009, I wanted to take my passion for education and games to the next level, so 
										I applied for an online degree in Game Design from the University of Advancing Technology (UAT) 
										in Tempe, AZ. But first, I had to figure out how to afford a second education.
									</p>
									<p>
										So I looked for a creative solution and learned the university grants scholarships to the top
										(4) applicants based on research papers submitted. Of these four, (1) lucky applicant would win the 
										prized, "Curiosita Scholarship", which included a full-year's tuition. So I grabbed my favorite 
										pencil and notebook, and three weeks later, submitted the "World of Academia" for a scholarship.
									</p>
									<p>
										Academia is a MMO role-playing university where students can learn curriculum through
										experience. Modeled like World of Warcraft, Academia represents many lands where curriculum
										is expressed thru a set of quests and storylines that scholars must complete to progress
										thru the world. Academia scholars must complete several quest lines that reward them with
										the skills and knowledge necessary to explore further in the lands of a chosen discipline.
										Just as knowledge is freedom in the real world, so is it in the World of Academia.
									</p>
									<p>
										My submission was awarded the Curiosita scholarship for it's concept of converting college
										course curriculum into world geography. It was well received, and my quest into game 
										design began.
									</p>

									<blockquote>
										"Your accomplishments to date have been commendable, and we expect you will be a very important
										contributing member of the University's student community."
										<span>Chrys C. Pistillo, M. Ed.</span>
									</blockquote>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			{/* aggressantBox */}
			{aggressantBox && (
				<div className="LightboxGal">
					<div className="closeGal">
						<button className="button-close" onClick={handleAggressantClose}></button>
					</div>
					<div className="v-center w-100">
						<div className="mainLightbox container">
							<div className="row g-5">
								<div className="col-lg-8">
									<div className="row g-4">
										<div className="col-lg-12 item">
											<img src="./img/gallery/aggressant/Aggressant.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/aggressant/Arkbridge.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/aggressant/Unrestrained.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-12 item">
											<img src="./img/gallery/aggressant/Beerijuana.jpg" alt="galleryimage" className="img-fluid" />
										</div>
									</div>
								</div>

								<div className="col-lg-4 de_project-info">
									<h3>Aggressant Web Design</h3>

									<div className="de_project-details">
										<div className="d-field">
											<i className="fa fa-user-o"></i>Focus: <span>Web Development</span>
										</div>
										<div className="d-field">
											<i className="fa fa-file-text-o"></i>Category: <span>Consulting</span>
										</div>
										<div className="d-field">
											<i className="fa fa-calendar-o"></i>Year: <span>2005</span>
										</div>
									</div>
									<br />
									<br />

									<h3>Helping Small Business With A Stylized Approach</h3>
									<p>
										As I began developing websites during the dot-com boom, I noticed there was a large
										market of small businesses looking for a website. There weren't web-building tools
										at the time, and having a website was a buzzword, so companies often had to pay
										large dollars to establish their online presence. And even then, very few sites had
										an edgy or stylistic appeal after those larger contracts completed.
									</p>
									<p>
										Having a background in art and engineering gave me an opportunity to help others
										create interesting websites cheaply. And I enjoyed the work. Over the span of (2)
										years, I consulted with over a dozen small companies and friendly-endeavors to 
										bring other's ideas or stories to life.
									</p>

									<blockquote>
										"Matthew, thank you for helping me spread my learnings in sales to others."
										<span>Mike Lynch, Arkbridge Partners</span>
									</blockquote>
									<blockquote>
										"I seriously think our guild has the best site."
										<span>Alefist - World of Warcraft, Runetotem</span>
									</blockquote>
									<blockquote>
										"Our fans love the site and it's helped us sell merch."
										<span>Zack Hicks - Drummer, Beerijuana</span>
									</blockquote>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			{/* blizzardBox */}
			{blizzardBox && (
				<div className="LightboxGal">
					<div className="closeGal">
						<button className="button-close" onClick={handleBlizzardClose}></button>
					</div>
					<div className="v-center w-100">
						<div className="mainLightbox container">
							<div className="row g-5">
								<div className="col-lg-8">
									<div className="row g-4">
										<div className="col-lg-12 item">
											<img src="./img/gallery/blizzard/Slide1.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/blizzard/Slide7.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/blizzard/Slide9.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-12 item">
											<img src="./img/gallery/blizzard/Slide5.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/blizzard/Slide15.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/blizzard/Slide16.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-12 item">
											<img src="./img/gallery/blizzard/Slide23.jpg" alt="galleryimage" className="img-fluid" />
										</div>
									</div>
								</div>

								<div className="col-lg-4 de_project-info">
									<h3>Blizzard QA Consult</h3>

									<div className="de_project-details">
										<div className="d-field">
											<i className="fa fa-user-o"></i>Focus: <span>Test Automation</span>
										</div>
										<div className="d-field">
											<i className="fa fa-file-text-o"></i>Category: <span>Consulting</span>
										</div>
										<div className="d-field">
											<i className="fa fa-calendar-o"></i>Year: <span>2015</span>
										</div>
										<div className="d-field">
											<i className="fa fa-external-link"></i>Preview: <span><a href="./img/gallery/blizzard/BlizzardQACOnsult.pptx" download="BlizzardQAConsult">Presentation</a></span>
										</div>
									</div>
									<br />
									<br />

									<h3>A Glorious Ask From a Glorious Company</h3>
									<p>
										After finishing my presentation at the IQPC Test Summit in San Francisco to (200) attendees, 
										I was approached by Chris Clark of Blizzard. He told me he loved the presentation and 
										wondered if could put something together to help teach some of the junior staff at Blizzard
										about test automation.
									</p>
									<p>
										Having been a mega-fan of Blizzard games for over (15) years, I was excited to help. So I
										went to the drawing board to culminate what I knew of test automation and games into a deck
										that was "Blizzy" in style.
									</p>
									<p>
										By the time I was done, the deck was (47) slides of pure Blizzard awesomeness. Chris received
										the deck very positively and applauded me. He even later gave me a call on my cell and offered
										me a job at Blizzard.
									</p>

									<blockquote>
										"This is great! Our staff will love it and we'd love to see you in SoCal soon."
										<span>Chris Clark - Director, QA Operations at Blizzard</span>
									</blockquote>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			{/* gamBox */}
			{gamBox && (
				<div className="LightboxGal">
					<div className="closeGal">
						<button className="button-close" onClick={handleGamClose}></button>
					</div>
					<div className="v-center w-100">
						<div className="mainLightbox container">
							<div className="row g-5">
								<div className="col-lg-8">
									<div className="row g-4">
										<div className="col-lg-12 item">
											<img src="./img/gallery/gam/GodsAndMonsters.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/gam/Gam1.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/gam/Gam2.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/gam/Gam3.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/gam/Gam4.jpg" alt="galleryimage" className="img-fluid" />
										</div>
									</div>
								</div>

								<div className="col-lg-4 de_project-info">
									<h3>Gods and Monsters</h3>

									<div className="de_project-details">
										<div className="d-field">
											<i className="fa fa-user-o"></i>Focus: <span>TableTop Game Development</span>
										</div>
										<div className="d-field">
											<i className="fa fa-file-text-o"></i>Category: <span>Hobby</span>
										</div>
										<div className="d-field">
											<i className="fa fa-calendar-o"></i>Year: <span>2018</span>
										</div>
									</div>
									<br />
									<br />

									<h3>The Battle For Reality Begins...</h3>
									<p>
										Throughout the ages, certain people have been able to tap into hidden power from beyond:
										shaman, magicians, heroes and legends. Written about in lost tomes, man has sought this
										power through the ages, yet only a handful have ever found... until recently.
									</p>
									<p>
										Why the walls between worlds collapsed, no-one knows for sure - but certain individuals
										suddenly found that they possessed the power to alter reality. When these individuals started
										to shape the world to their liking, they soon came into conflict with one another. They began
										to battle for the one resource they could not create - the power from beyond. Wherever the walls
										of this world are thin, wherever this power leaks into our existence, Gods and Monsters will
										clash for control of it.
									</p>
									<p>
										You will assume the role of one of the new 'gods' and assemble your followers, monsters, and
										powers into a (60) card deck. You will spend your power by summoning troops, performing miracles
										and hindering your opponent while your search the area for wells of power. 
									</p>
									<p>
										Who's vision will become reality? You decide in Gods and Monsters.
									</p>

									<blockquote>
										"Do you like miniatures skirmish games? (I do) Do you like giant miniatures? (I do). Well, then
										Gods and Monsters by Altered Reality Games might just be for you!"
										<span>Polar_Bear</span>
									</blockquote>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			{/* wogBox */}
			{wogBox && (
				<div className="LightboxGal">
					<div className="closeGal">
						<button className="button-close" onClick={handleWogClose}></button>
					</div>
					<div className="v-center w-100">
						<div className="mainLightbox container">
							<div className="row g-5">
								<div className="col-lg-8">
									<div className="row g-4">
										<div className="col-lg-12 item">
											<img src="./img/gallery/wog/WorkoutGears.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-4 item">
											<img src="./img/gallery/wog/Wog1.png" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-4 item">
											<img src="./img/gallery/wog/Wog2.png" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-4 item">
											<img src="./img/gallery/wog/Wog3.png" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-12 item">
											<img src="./img/gallery/wog/Wog4.jpg" alt="galleryimage" className="img-fluid" />
										</div>
									</div>
								</div>

								<div className="col-lg-4 de_project-info">
									<h3>Workout Gears</h3>

									<div className="de_project-details">
										<div className="d-field">
											<i className="fa fa-user-o"></i>Focus: <span>Mobile Development</span>
										</div>
										<div className="d-field">
											<i className="fa fa-file-text-o"></i>Category: <span>Hobby</span>
										</div>
										<div className="d-field">
											<i className="fa fa-calendar-o"></i>Year: <span>2010</span>
										</div>
									</div>
									<br />
									<br />

									<h3>Designed to Make You Strong</h3>
									<p>
										Workout Gears is a series of apps designed to help you enjoy your workouts without the hassles
										of scheduling and planning. With easy guidance and direction, Workout Gears allows you to stay
										focused on what matters most - your spirit and drive.
									</p>

									<h4>Friendly But Powerful</h4>
									<p>
										Workout Gears makes it easy to train in multiple disciplines, allowing you to explore and master
										fitness without over-thinking the process. Configure the challenge of your workout with a few clicks,
										and spend the rest of your workout being the best you can be. Workout Gears will take care of the rest.
									</p>

									<h4>Growing Library of Gears</h4>
									<p>
										Workout Gears offers several different gears to build a fitness program that suits your needs. Want
										to practice Yoga, Kickboxing, and Weight Lifting? No problem. Customize your very own fitness engine
										in minutes.
									</p>

									<blockquote>
										"This app is solid. I've taken a lot of martial arts classes and it definitely captures the spirit."
										<span>GoldenFists</span>
									</blockquote>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			{/* autoGearsBox */}
			{autoGearsBox && (
				<div className="LightboxGal">
					<div className="closeGal">
						<button className="button-close" onClick={handleAutoGearsClose}></button>
					</div>
					<div className="v-center w-100">
						<div className="mainLightbox container">
							<div className="row g-5">
								<div className="col-lg-8">
									<div className="row g-4">
										<div className="col-lg-12 item">
											<img src="./img/gallery/autoGears/Slide1.jpg" alt="galleryimage" className="img-fluid" />
										</div>

										<div className="col-lg-6 item">
											<img src="./img/gallery/autoGears/Slide24.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/autoGears/Slide25.jpg" alt="galleryimage" className="img-fluid" />
										</div>

										<div className="col-lg-6 item">
											<img src="./img/gallery/autoGears/Slide26.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/autoGears/Slide27.jpg" alt="galleryimage" className="img-fluid" />
										</div>

										<div className="col-lg-6 item">
											<img src="./img/gallery/autoGears/Slide28.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/autoGears/Slide29.jpg" alt="galleryimage" className="img-fluid" />
										</div>

										<div className="col-lg-6 item">
											<img src="./img/gallery/autoGears/Slide30.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/autoGears/Slide31.jpg" alt="galleryimage" className="img-fluid" />
										</div>

										<div className="col-lg-12 item">
											<img src="./img/gallery/autoGears/Slide32.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-12 item">
											<img src="./img/gallery/autoGears/Slide33.jpg" alt="galleryimage" className="img-fluid" />
										</div>
									</div>
								</div>

								<div className="col-lg-4 de_project-info">
									<h3>Gears of Automation</h3>

									<div className="de_project-details">
										<div className="d-field">
											<i className="fa fa-user-o"></i>Focus: <span>Test Automation</span>
										</div>
										<div className="d-field">
											<i className="fa fa-file-text-o"></i>Category: <span>Training</span>
										</div>
										<div className="d-field">
											<i className="fa fa-calendar-o"></i>Year: <span>2020</span>
										</div>
										<div className="d-field">
											<i className="fa fa-external-link"></i>Preview: <span><a href="./img/gallery/autoGears/GearsOfAutomation.pptx" download="GearsOfAutomation">Presentation</a></span>
										</div>
									</div>
									<br />
									<br />

									<h3>Learning How To Build Automation Effectively</h3>
									<p>
										Understanding the fundamental units of automation and their function gives us the means to <i>build</i> solutions. But nderstanding
										patterns and best practices gives us the means to <i>build more powerful solutions cheaper with less engineers</i>.
									</p>

									<p>
										Leveraging roles increases team productivity and chances of successful automation. While Leveraging teams
										reduces engineering, improves guidance, and creates clear career paths.
									</p>
									<p>
										Whether you're on a budget or not, following the best practices in this deck increases your chances of
										successful automation dramatically. And even better, it'll leave you with a strong team that
										can tackle anything.
									</p>
									<br/>

									<blockquote>
										"This makes a lot of sense and helps standardize the mysteries of SDET craftsmanship."
										<span>Curtis Stuehrenberger</span>
									</blockquote>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			{/* autoCostsBox */}
			{autoCostsBox && (
				<div className="LightboxGal">
					<div className="closeGal">
						<button className="button-close" onClick={handleAutoCostsClose}></button>
					</div>
					<div className="v-center w-100">
						<div className="mainLightbox container">
							<div className="row g-5">
								<div className="col-lg-8">
									<div className="row g-4">
										<div className="col-lg-12 item">
											<img src="./img/gallery/autoCosts/Slide1.jpg" alt="galleryimage" className="img-fluid" />
										</div>

										<div className="col-lg-6 item">
											<img src="./img/gallery/autoCosts/Slide3.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/autoCosts/Slide4.jpg" alt="galleryimage" className="img-fluid" />
										</div>

										<div className="col-lg-6 item">
											<img src="./img/gallery/autoCosts/Slide5.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/autoCosts/Slide6.jpg" alt="galleryimage" className="img-fluid" />
										</div>

										<div className="col-lg-6 item">
											<img src="./img/gallery/autoCosts/Slide7.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-6 item">
											<img src="./img/gallery/autoCosts/Slide8.jpg" alt="galleryimage" className="img-fluid" />
										</div>

										<div className="col-lg-12 item">
											<img src="./img/gallery/autoCosts/Slide9.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-12 item">
											<img src="./img/gallery/autoCosts/Slide10.jpg" alt="galleryimage" className="img-fluid" />
										</div>
										<div className="col-lg-12 item">
											<img src="./img/gallery/autoCosts/Slide11.jpg" alt="galleryimage" className="img-fluid" />
										</div>
									</div>
								</div>

								<div className="col-lg-4 de_project-info">
									<h3>Costs of Automation</h3>

									<div className="de_project-details">
										<div className="d-field">
											<i className="fa fa-user-o"></i>Focus: <span>Test Automation</span>
										</div>
										<div className="d-field">
											<i className="fa fa-file-text-o"></i>Category: <span>Training</span>
										</div>
										<div className="d-field">
											<i className="fa fa-calendar-o"></i>Year: <span>2017</span>
										</div>
										<div className="d-field">
											<i className="fa fa-external-link"></i>Preview: <span><a href="./img/gallery/autoCosts/CostsOfAutomation.pptx" download="CostsOfAutomation">Presentation</a></span>
										</div>
									</div>
									<br />
									<br />

									<h3>Learning How To Build Automation Effectively</h3>
									<p>
										We need roles and teams to optimize initiatives and tasks, but how do we prove they actually work?
									</p>
									<p>
										The costs (and efficiency) of automation are best realized over time. Most organizations maintain ongoing automation efforts with few metrics attached. As such, the impact of test tools and process is often not realized. However, these test metrics do exist and can be applied to our current situation.
									</p>
									<br />

									<blockquote>
										"This will really help us determine annual budget/headcount and prove our work makes a difference."
										<span>Anirudh Daga</span>
									</blockquote>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

		</div>
	);
}

export default Gallery;