import React from 'react';
import {
	CircularProgressbar,
	buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import AnimatedProgressProvider from "../layout/AnimatedProgressProvider";

import AOS from 'aos';
AOS.init();


const hero = () => {
	return (
		<div className="v-center">
			<div className="container">
				<div className="row">
					<div className="col-md-12 text-center">
						<h2 data-aos="fade-up"
							data-aos-delay="0"
							data-aos-duration="1000"
							data-aos-easing="ease"
							data-aos-once="true">Professional Overview</h2>
						<div className="slim-border-left"
							data-aos="fade-up"
							data-aos-delay="20"
							data-aos-duration="1000"
							data-aos-easing="ease"
							data-aos-once="true"
						></div>
					</div>
					<div className="col-md-8 text-center m-auto"
						data-aos="fade-up"
						data-aos-delay="60"
						data-aos-duration="1000"
						data-aos-easing="ease"
						data-aos-once="true">
						<p>
							Versatile principal / director of QA / Automation with <span className="color">(20+)</span> years of development and management
							experience across <span className="color">(10+)</span> companies. Strength building and managing local and remote automation
							teams, defining and instilling org-wide processes, establishing and maintaining vendor relations,
							projecting headcount / budget, defining roadmaps, overseeing multiple automation programs, architecting
							automation frameworks for team / group / org usage, and presenting org / automation efforts to large audiences.
						</p>
					</div>
				</div>
				<br/>
				<div className="row">
					<div className="col-md-12 text-center">
						<h2 data-aos="fade-up"
							data-aos-delay="0"
							data-aos-duration="1000"
							data-aos-easing="ease"
							data-aos-once="true">Summary of Accomplishments</h2>
						<div className="slim-border"
							data-aos="fade-up"
							data-aos-delay="20"
							data-aos-duration="1000"
							data-aos-easing="ease"
							data-aos-once="true"
						></div>
					</div>
					<div className="col-md-8 text-center m-auto"
						data-aos="fade-up"
						data-aos-delay="60"
						data-aos-duration="1000"
						data-aos-easing="ease"
						data-aos-once="true">
						<ul style={{ textAlign: "left" }}>
							<li><span className="color">(15+)</span> years of IC dev with an emphasis on automation frameworks, dashboards, services, apps, and games.</li>
							<li><span className="color">(5+)</span> years managing teams of <span className="color">(4 - 12)</span> software / tool developers and <span className="color">(5 - 30)</span> manual / SDET testers.</li>
							<li><span className="color">(1.5+)</span> years in director roles leading QA orgs of <span className="color">(25 - 550)</span> manual / SDET testers.</li>
							<li><span className="color">(2+)</span> years assuming PM responsibilities defining and driving roadmaps for automation.</li>
							<li><span className="color">(5K+)</span> test cases and scripts authored.</li>
							<li><span className="color">(20+)</span> automation frameworks architected.</li>
							<li><span className="color">(40+)</span> projects automated.</li>
							<li><span className="color">(300+)</span> interviews hosted. Great at designing interview strategies and building teams to capacity.</li>
							<li><span className="color">(60+)</span> engineers mentored.</li>
							<li><span className="color">(30+)</span> presentations created and given to audiences of <span className="color">(20 - 400)</span> attendees, including global QA forums.</li>
						</ul>
					</div>
				</div>
				<br />
				<div className="row">
					<div className="col-md-12 text-center">
						<h2 data-aos="fade-up"
							data-aos-delay="0"
							data-aos-duration="1000"
							data-aos-easing="ease"
							data-aos-once="true">Key Skills</h2>
						<div className="slim-border-right"
							data-aos="fade-up"
							data-aos-delay="20"
							data-aos-duration="1000"
							data-aos-easing="ease"
							data-aos-once="true"
						></div>
					</div>
					<div className="col-md-8 text-center m-auto"
						data-aos="fade-up"
						data-aos-delay="60"
						data-aos-duration="1000"
						data-aos-easing="ease"
						data-aos-once="true">
						<ul style={{ textAlign: "left" }}>
							<li>Strong experience coding in <span className="color">C#, Java, Javascript, Blueprint, and C++</span>.</li>
							<li>Strong CI/CD automation skill with <span className="color">Perforce, HelixCore, Git, Jenkins, CodeBuild / Pipeline, and GitPipeline</span>.</li>
							<li>Versed with several test frameworks including <span className="color">MSTest, NUnit, JUnit, TestNG, Mocha, Cucumber, and JMeter.</span></li>
							<li>Strong experience with RPC-centric frameworks like <span className="color">Selenium, Appium, and self-crafted RPC frameworks.</span></li>
							<li>Versed with load frameworks including <span className="color">JMeter, LoadForge, and hand-crafted distributed runners.</span></li>
							<li>Strong experience with BDD / fluency including <span className="color">Cucumber, JBehave, SpecFlow, and hand-crafted utilities.</span></li>
							<li>Capable with AWS <span className="color">ApiGateway, Lambda, RDS, Dynamo, S3, QuickSight, CloudFormation, and CloudWatch.</span></li>
							<li>Strong familiarity with game engines including <span className="color">Unity 2D/3D/XR, Unreal 2D/3D</span>.</li>
							<li>Versatile. Can function as <span className="color">SDET, Dev, Test, PM, Lead, Manager, and Director</span>. (not an exaggeration)</li>
							<li>Extremely well-written and an excellent communicator. Great presentation developer and presenter.</li>
							<li>Great team leader. Strong motivator. Passionate. Supportive. Encouraging.</li>
							<li>Strong UX talent with a keen eye for visuals that work within functional parameters.</li>
							<li>Very capable of taking ideas and realizing them technically with high-quality results.</li>
							<li>Strong architecture and system design skills.</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default hero;