import React from 'react';
import AOS from 'aos';
AOS.init();



const hero = () => {
	return(
		<div className="container">
			<div className="row">
				<div className="col-md-12 text-center">
	                <h2>Resume</h2>
	                <div className="space-border"></div>
	            </div>
			</div>
			<div className="row gh-5">
				<div className="col-lg-6" data-aos="fade-up" data-aos-once="true">
                    <div className="p-4">
                        <h3 className="s_border">Experiences</h3>
                        <ul className="d_timeline">
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">5/2022 - 8/2022</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Test Director</span>
                                	<span className="d_company">Experis</span>
                                    Directed a staff of <span className="color">(550+)</span> manual QA (5 managers, 10+ leads, 90+ test engineers, and 400+ test associates)
                                    across <span className="color">(10+)</span> Microsoft titles. Drove the creation of new training procedures and org-wide process improvements for manual QA, automation,
                                    and tools engineering. Helped secure a <span className="color">($2M)</span> increase in program revenue, dispelled <span className="color">(2+)</span> employee
                                    walkouts due to wage issues (&lt; 1% delivery impact), and established a new tools team/roadmap to improve test delivery.
                                    <br />
                                    <br />
                                    Reason for leaving: Disliked how executive management handled program finances, staff wages, training, and internal/partner transparency.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2/2022 - 4/2022</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Principal Game Developer</span>
                                	<span className="d_company">Dreamworld</span>
                                    Multi-faceted role with responsibilities including gameplay development <span className="color">(Unreal | C++ | Blueprint)</span>,
                                    server development <span className="color">(Unreal | C# | Go)</span>, business analysis <span className="color">(Nuclino | HTML | Javascript | Bootstrap | React)</span>,
                                    game design, level design, lore, and community involvement. Invented Dreamworld's "Playcraft" feature allowing
                                    players to 'color' the persistent voxel world with customized games and coded the client/server bits. Created
                                    and maintained a competitive analysis journal covering (50+) MMORPG / Sandbox titles and (800+) mods. Reinvented
                                    client UX and coded new UI system/views <span className="color">(Unreal | Slate UI)</span>.
                                    <br />
                                    <br />
                                    Reason for leaving: Disagreed with CEO's stance on "no game design" and doubted the long-term success of the title.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">8/2018 - 12/2021</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Software Development Manager of Test Automation</span>
                                    <span className="d_company">Amazon Games</span>
                                    Founded and managed Amazon Games Test Platform team of <span className="color">(4+)</span> tools engineers. Drafted 1/2/5-year automation 
                                    strategy. Oversaw <span className="color">(20+)</span> automation engineers across all internal studios.  Created automation roadmaps 
                                    for New World, Crucible, and several unannounced PC/Mobile titles. Increased daily automation results 
                                    from <span className="color">(100 -&gt; 14500+/daily)</span>. Established and maintained automation programs with vendors
                                    <span className="color">(Tek Systems | Lionbridge | Experis)</span> in North America, UK, and Poland. Helped craft annual headcount/budget
                                    for automation engineers. Prototyped Unreal automation frameworks for new unreleased AAA titles <span className="color">(Unreal, C++,
                                    Blueprint, Unity, C#, Python, SQL, AWS Lambda | RDS | S3 | QuickSight | CodeBuild | DeviceFarm, Perforce, Helix, JIRA, Confluence, Jenkins)</span>.
                                    <br />
                                    <br />
                                    Reason for leaving: Change of scenery after being at Amazon for (7+) years.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2/2015 - 8/2018</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Senior Software Development Engineer in Test (SDET)</span>
                                    <span className="d_company">Amazon Alexa</span>
                                    Researched, designed, pitched, and founded Alexa's Test Platform team <span className="color">(currently 20+ SDETs)</span>. Hand-crafted the Alexa Test 
                                    Framework including the Amazon-wide "Master Test Report" that allows all Amazon teams to analyze automated results in their 
                                    release pipelines <span className="color">(Java | Junit | TestNG | GIT | JIRA | Confluence | AWS Lambda | RDS | Dynamo | S3 | QuickSight | 
                                    CodeBuild | DeviceFarm | HTML | Javascript | JQuery | Mustache | Selenium | Appium)</span>. Increased daily automation results 
                                    from <span className="color">(100 -&gt; 12M+/daily - that's not a typo)</span>.
                                    <br />
                                    <br />
                                    Reason for leaving: The platform team was norming and I wanted to pursue an opportunity with Amazon Games.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2/2014 - 2/2015</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Director of Test Automation</span>
                                    <span className="d_company">Pole To Win International</span>
                                    Created a new set of Quality Engineering services for Pole To Win International. Built and managed multiple
                                    teams of <span className="color">(5 - 20+)</span> SDETs in Bangalore, Glasgow, London, Austin, and SF. Defined the business model for
                                    selling test automation to companies and participated in business presentations globally. Partnered with
                                    multiple entertainment and e-commerce companies <span className="color">(Blizzard | Riot | Capcom | Jagex |
                                    Rovio | Rosetta Stone)</span> to help them automate their titles.
                                    <br />
                                    <br />
                                    Reason for leaving: Low sales contracts. Pursue an opportunity at Amazon Alexa.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">4/2013 - 2/2014</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Senior Manager of Test Automation / Tools</span>
                                    <span className="d_company">Barnes & Noble / Nook</span>
                                    Managed a team of <span className="color">(6+)</span> QA Engineers and
                                    <span className="color">(2+)</span> Tool Engineers. Automate functional,
                                    load, and perf testing of Nook's digital backend <span className="color">
                                    (Java | JMeter | HTML | Javascript | Bootstrap | Selenium)</span> producing <span className="color">(40K+/mo)</span> results.Also fostered and led the
                                    development of tools for ops, engineering, and business teams including a 
                                    dashboard to track all dev tasks and delegate them to QA/automation. 
                                    Oversaw and approved patch/weekly/monthly live releases. 
                                    <br />
                                    <br />
                                    Reason for leaving: Pursue a global director promotion in the games testing
                                    space at Pole to Win.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">8/2012 - 4/2013</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Manager of Test Automation</span>
                                    <span className="d_company">Electronic Arts Digital Platform</span>
                                    Created and led onshore/offshore team of <span className="color">(5+)</span> SDETs
                                    to automate testing for EA's iOS games platform <span className="color">(Plants
                                    vs.Zombies, FIFA, Sims, Simpson's Tapped Out)</span>. Coded the initial
                                    framework <span className="color">(C# | Objective-C | SQL | HTML | Javascript |
                                    MSTest | Selenium | Appium)</span> and build pipeline with
                                    Jenkins <span className="color">(Jenkins | Bash | Cmd)</span>. Also created a
                                    load/stress test for EADP services <span className="color">(Java | JMeter |
                                    Jenkins)</span>. 
                                    <br />
                                    <br />
                                    Reason for leaving: Pursue an opportunity with former Adobe employees at Nook.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">5/2011 - 1/2012</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Senior Software Development Engineer in Test (SDET)</span>
                                    <span className="d_company">Zynga</span>
                                    Created Zynga's performance automation framework and dashboard <span className="color">(Flash |
                                    ActionScript | Java | ASP.NET | HTML | Javascript | JQuery | Selenium)</span> for all Flash
                                    games <span className="color">(Poker, Farmville, Cityville)</span> used by <span className="color">(25+)
                                    </span> SDETs. Created Zynga's distributed test automation framework <span className="color">(C# |
                                    WCF)</span>. Also developed many smaller operational tools, build systems, and org-wide games 
                                    test dashboard.
                                    <br />
                                    <br />
                                    Reason for leaving: Executive management wanted to offshore automation to India and my role would change.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">10/2009 - 5/2011</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Senior Software Development Engineer in Test (SDET)</span>
                                    <span className="d_company">Netflix</span>
                                    Invented Netflix's UI automation framework <span className="color">(C# | HTML |
                                    Javascript | Jenkins | MSTest)</span>, allowing us to automate the
                                    Netflix streaming UI across TVs, web, consoles, and mobile. Created 
                                    an automated test framework for <span className="color">(5+)</span> SDETs
                                    and a semi-automated framework for <span className="color">(10+)</span> manual
                                    QA. Design and implement a certification harness and test system for Netflix 
                                    third-party developers <span className="color">(C# | Spring | Grails | HTML |
                                    Javascript)</span>. Help design UX and provide initial user feedback. 
                                    <br />
                                    <br />
                                    Reason for leaving: Pursue opportunity in games at Zynga.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">3/2008 - 11/2009</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Senior Software Development Engineer in Test (SDET)</span>
                                    <span className="d_company">Adobe Systems</span>
                                    Developed test automation for Adobe Flex <span className="color">(C# | Flash | Flex |
                                    ActionScript | SQL | Html | Javascript | JQuery | NUnit)</span> that
                                    produced <span className="color">(30K+ results)</span>. Oversaw testing of Flex
                                    Effects and other smaller features <span className="color">(400+ results/daily)</span>. Created
                                    the "Flex Files" dashboard for analyzing test runs/results and improved test
                                    productivity by <span className="color">(30%)</span>. Developed agile-based tool to
                                    assign and conclude test/automation tasks.
                                    <br />
                                    <br />
                                    Reason for leaving: Pursue opportunity with former MSFT employees at Netflix.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">3/2006 - 3/2008</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Software Development Engineer (SDE)</span>
                                    <span className="d_company">Microsoft IPTV</span>
                                    Developed services for video ingestion of the IPTV pipeline <span className="color">(C# | .NET)</span>.
                                    Created dashboards to manage video feeds / content / processes <span className="color">(C# | MSSQL |
                                    ASP.NET | HTML | Javascript)</span>. 
                                    <br />
                                    <br />
                                    Reason for leaving: Change of scenery after being at MSFT for (8) years.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">11/2000 - 3/2006</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Software Development Engineer in Test (SDET)</span>
                                    <span className="d_company">Microsoft IPTV</span>
                                    Created the functional test framework <span className="color">(C# |
                                    SQL | HTML | Javascript | MSTest)</span> for IPTV automation, used
                                    by <span className="color">(10+)</span> SDETS. Created the MTBF
                                    distributed automation framework to mass-test IPTV's set-top box
                                    labs <span className="color">(250K+ results/mo)</span>. Created the
                                    IPTV performance framework/dashboard <span className="color">(50K+ results/mo)</span> and
                                    lead a team of <span className="color">(2)</span> manual testers
                                    to validate set-top box performance.
                                    <br />
                                    <br />
                                    Reason for leaving: Director asked me to help with the services team and the
                                    test frameworks were running smoothly for the SDETs.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="col-lg-6" data-aos="fade-up" data-aos-once="true">
                    <div className="p-4">
                        <h3 className="s_border">Education</h3>
                        <ul className="d_timeline">
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2009 - 2010</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">BA in Game Design</span>
                                    <span className="d_company">University of Advancing Technology</span>
                                    Submitted my research paper for turning online education into an MMORPG
                                    and was <span className="color">awarded a full scholarship</span>.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">1996 - 1997</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">BS in Computer Science</span>
                                    <span className="d_company">University of California at Santa Cruz</span>
                                    Continued bachelor's in computer science with a <span className="color">3.85 GPA</span>.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">1993 - 1995</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">AS in Computer Science</span>
                                    <span className="d_company">Foothill College</span>
                                    Began my education in Computer Science with a <span className="color">3.88 GPA</span>.
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div className="p-4">
                        <h3 className="s_border">Hobby Projects</h3>
                        <ul className="d_timeline">
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2022</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Test Forge</span>
                                    AWS-based test platform for automated testing and test management.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2022</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Warp Runner</span>
                                    Core-based third-person multiplayer game focused on maze running, combat, and gambling.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2018</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Gods And Monsters</span>
                                    Tabletop RPG and miniatures focused on command-and-control mechanics using card-based deckbuilding.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2016</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Warpin Creation (VR)</span>
                                    Unity-based VR game focused on combat, puzzle solving, and speed.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2011</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Flirt</span>
                                    Web-based mobile app focused on dating and matchmaking through conversation-based games.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2010</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Workout Gears</span>
                                    Unity-based mobile fitness app focused on martial arts.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2009</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">World of Academia</span>
                                    Web-based MMORP desktop game focused on teaching various academic disciplines.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2007</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Story Forge</span>
                                    Web-based community app for tabletop RPGS, like Roll20.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2005</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Aggressant Studios</span>
                                    Custom web-development, focused on small businesses seeking attractive websites.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
			</div>
		</div>
	);
}

export default hero;