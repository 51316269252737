import React from 'react';
import { Parallax } from "react-parallax";
import AOS from 'aos';
AOS.init();


const image1 = "./img/background/3.jpg";

const BlockquoteVersatile = () => {
	return (
		<div className="section bg-top bg-bottom py-0"
		>
			<Parallax className="pb-5" bgImage={image1} strength={300}>
				<div className="py-5 position-relative"
					data-aos="fade"
					data-aos-delay="100"
					data-aos-duration="1000"
					data-aos-easing="ease"
					data-aos-once="true">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-md-10 offset-md-1">
								<div className="spacer-double"></div>
								<blockquote className="q-big">
									<i className="d-big icon_quotations"></i>
									Recruiters often ask, "Why would you take a lesser role?". And I tell them, 
									"The need for automation is usually accompanied by a lack of knowledge, 
									and I'd rather earn upward. When directing down, you're often challenged by assumptions 
									from many who don't know better. When earning up, you win with proof,
									and teach them."
									<span className="d-quote-by">Matthew Finitz</span>
								</blockquote>
							</div>
						</div>
					</div>
				</div>
			</Parallax>
		</div>
	);
}

export default BlockquoteVersatile;